<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-13 15:38:48
 * @LastEditTime: 2023-11-07 18:31:17
 * @LastEditors: tangjz
 * @FilePath: \cbsd:\hq\mfzj\src\views\layout\work\okr\evaluation\index.vue
-->
<template>
  <div class="okr-evaluation" v-loading="loading">
    <el-affix :offset="71">
      <EvaluteTop :tabSel="tabSel" @changeValue="changeTab"></EvaluteTop>
    </el-affix>

    <div class="evaluation-bottom">
        <div v-if="auditList.length" v-infinite-scroll="loadMore" infinite-scroll-immediate="false" infinite-scroll-distance="0">
          <OkrTemplate
            v-for="item in auditList"
            :key="item"
            :showRightBtn="false"
            :isEvaluate="true"
            :okrData="item">
            <template #footer>
              <template v-if="tabSel === '待审批'">
                <span class="pass-btn cursor-pointer" @click="pass(item.id)">通过</span>
                <span class="reject-btn cursor-pointer" @click="reject(item.id)">驳回</span>
              </template>
              <span class="evalution-btn cursor-pointer" v-else @click="goToEvalute(item)">去评价</span>
            </template>
          </OkrTemplate>
        </div>
        <empty-data v-else :image="image" :description="description" size="170"></empty-data>
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="驳回原因"
      custom-class="reject-dialog"
      :show-close="false"
      :close-on-click-modal="false"
      center
    >
      <el-input
        v-model="textarea"
        :rows="2"
        type="textarea"
        placeholder="请描述你的驳回原因"
        resize="none"
        maxlength="500"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="confirmRejct"
            >确认驳回</el-button
          >
          <el-button @click="cancelReject">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'
import OkrTemplate from '../myokr/component/OkrTemplate'
import EvaluteTop from './components/EvaluteTop.vue'
import { confirm, dialogMsg } from '@/utils/dialogMsg.js'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { getOkrLeadAuditList, getOkrLeadEvaluateList, editOkrStatus, changeEvaluateStatus, getOkrEvaluateCount } from '@/apis/okr.js'
import { getsign } from '@/utils/tools.js'

export default {
  components: {
    OkrTemplate,
    EvaluteTop
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      tabSel: '待审批',
      description: '',
      image: '',
      okrList: [
        { id: 1, content: '', weight: 100, disabled: false },
        { id: 1, content: '', weight: 100, disabled: false },
        { id: 1, content: '', weight: 100, disabled: false }
      ],
      dialogVisible: false,
      textarea: '',
      params: {
        limit: 10,
        page: 1
      },
      auditList: [],
      rejectId: '',
      evaluateCount: computed(() => store.state.okr.evaluateCount),
      noMore: computed(() => store.state.noMore),
      loading: false
    })

    const getAuditList = (type = 'refresh') => {
      function getNumber () {
        getOkrEvaluateCount().then(({ code, data }) => {
          if (code === 0) {
          // console.log(data)
            store.commit('okr/setEvaluateCount', data)
          }
        })
      }
      state.loading = true
      if (type !== 'refresh') {
        state.params.page += 1
        store.commit('setLoadMore', true)
        if (state.noMore) {
          store.commit('setLoadMore', false)
          state.loading = false
          return false
        }
      } else {
        state.auditList = []
        state.params.page = 1
      }

      if (state.tabSel === '待审批') {
        getOkrLeadAuditList(state.params).then(({ code, data, msg }) => {
          if (code === 0) {
            // console.log(data)
            state.params.page === 1 ? (state.auditList = data.data) : (state.auditList.push(...data.data))
            data.data.length < 10 ? store.commit('setNoMore', true) : store.commit('setNoMore', false)
          } else {
            dialogMsg('error', msg)
          }
        }).finally(() => {
          store.commit('setLoadMore', false)
          if (!state.auditList.length) store.commit('setNoMore', false)
          state.loading = false
          getNumber()
        })
      } else {
        getOkrLeadEvaluateList(state.params).then(({ code, data, msg }) => {
          if (code === 0) {
            data.data.map(i => (i.isHighEvalute = true))
            state.params.page === 1 ? (state.auditList = data.data) : (state.auditList.push(...data.data))
            data.data.length < 10 ? store.commit('setNoMore', true) : store.commit('setNoMore', false)
          } else {
            dialogMsg('error', msg)
          }
        }).finally(() => {
          store.commit('setLoadMore', false)
          if (!state.auditList.length) store.commit('setNoMore', false)
          state.loading = false
          getNumber()
        })
      }
    }

    watch(() => state.tabSel, (newValue) => {
      if (newValue === '待审批') {
        state.image = 'no-okr-evalution'
        state.description = '还没有目标需要审核'
      } else {
        state.image = 'no-okr-evalution-comment'
        state.description = '周期未结束，没有评价哦'
      }
    }, { immediate: true })

    watch(() => route.params, (newValue) => {
      if (route.params.tabName) {
        state.tabSel = route.params.tabName
      }
    }, { deep: true, immediate: true })

    const changeTab = (value) => {
      state.tabSel = value
      state.params.page = 1
      getAuditList()
    }

    const goToEvalute = (value) => {
      // 修改状态为正在评价中
      changeEvaluateStatus({ id: value.id, evaluate_status: 2 }).then(({ code }) => {
        if (code === 0) {
          store.commit('okr/setHighComenting', true) // 记录评价中状态
          router.push({ name: 'evaluatePage' })
          store.commit('okr/setEvaluateStatusAgo', value.evaluate_status) // 保存去评价前状态
          store.commit('okr/setHighComenting', true) // 修改评价中状态
          value.isHighEvalute = true
          store.commit('okr/setHighEvaluteData', JSON.parse(JSON.stringify(value)))
        }
      })
    }

    const pass = (id) => {
      confirm({
        message: '确定通过审核吗？',
        success: () => {
          // console.log(id)
          editOkrStatus({ id, status: 4 }).then(({ code, data, msg }) => {
            if (code === 0) {
              // console.log(data)
              dialogMsg('success', msg)
              getAuditList()
              document.scrollingElement.scrollTop = 0
              calcCount('audit')
            } else {
              if (code === 80009) {
                getAuditList()
                calcCount('audit')
              }
              dialogMsg('error', msg)
            }
          })
        },
        cancel: () => {
          return false
        }
      })
    }

    const reject = (id) => {
      editOkrStatus({ id, status: 7 }).then(({ code }) => {
        if (code === 0) {
          state.dialogVisible = true
          state.rejectId = id
        }
      })
    }

    // 驳回
    const confirmRejct = () => {
      if (!state.textarea.trim().length) return dialogMsg('error', '请输入驳回原因')
      editOkrStatus({ id: state.rejectId, status: 3, reject_content: state.textarea }).then(({ code, data, msg }) => {
        if (code === 0) {
          // console.log(data)
          dialogMsg('success', msg)
          getAuditList()
          calcCount('audit')
          document.scrollingElement.scrollTop = 0
        } else {
          if (code === 80009) {
            getAuditList()
            calcCount('audit')
          }
          dialogMsg('error', msg)
        }
      })
      state.dialogVisible = false
      state.textarea = ''
    }

    // 取消驳回
    const cancelReject = () => {
      editOkrStatus({ id: state.rejectId, status: 2 }).then(({ code }) => {
        if (code === 0) {
          state.dialogVisible = false
          state.textarea = ''
        }
      })
    }

    const loadMore = () => {
      getAuditList('nofresh')
    }

    // 计算数量
    const calcCount = (type) => {
      const { audit, evaluate } = JSON.parse(JSON.stringify(state.evaluateCount))
      const data = {
        audit: audit,
        evaluate: evaluate
      }
      type === 'audit' ? data.audit = data.audit - 1 : data.evaluate = data.evaluate - 1
      store.commit('okr/setEvaluateCount', data)
    }

    watch(() => route.params.evaluteSuccess, (newValue) => {
      newValue && calcCount('evalute')
    }, { immediate: true })

    onBeforeRouteLeave(() => {
      store.commit('setNoMore', false)
    })

    const handleUnload = (ev) => {
      if (state.dialogVisible) {
        const P = new FormData()
        const { token, sign, timestamp } = getsign()
        P.append('token', token)
        P.append('sign', sign)
        P.append('timestamp', timestamp)
        P.append('id', state.rejectId)
        P.append('status', 2)
        const url = process.env.VUE_APP_URL + 'api/okr/status'
        navigator.sendBeacon(url, P)
      }
    }
    onMounted(() => {
      getAuditList()
      document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'hidden') {
          handleUnload()
        }
      })
    })
    return {
      ...toRefs(state),
      changeTab,
      goToEvalute,
      pass,
      reject,
      confirmRejct,
      loadMore,
      cancelReject
    }
  }
}
</script>

<style lang="less" scoped>
// :deep(.el-affix--fixed) {
//   z-index: 2 !important;
// }
.okr-evaluation {
  position: relative;
  width: 955px !important;
}
.scroll-bar {
    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f6f9fa;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
}

.evaluation-bottom {
  margin-top: 8px;
  :deep(.el-empty) {
    width: 955px;
    height: 570px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    .el-empty__description {
      margin-top: 0;
      font-size: 14px;
    }
  }
}
.evaluation-bottom {
  :deep(.item-weight) {
    width: 72px !important;
  }
}
:deep(.okr-template) {
  margin-top: 8px;
}

.pass-btn,
.reject-btn {
  padding: 7px 31px;
  border-radius: 30px;
  font-size: 13px;
}
.pass-btn,
.evalution-btn {
  background: @active-text-color;
  color: #fff;
  border-radius: 30px;
  font-size: 13px;
}
.reject-btn {
  background: #FFFFFF;
  color: @active-text-color;
  border: 1px solid @active-text-color;
  margin-left: 12px;
}

.evalution-btn {
  padding: 7px 14px;
}
:deep(.el-dialog.reject-dialog){
  width: 596px !important;
  height: 295px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 24px 42px;
  .el-dialog__header {
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    color: #333;
    margin-bottom: 16px;
  }
  .el-dialog__body {
    padding: 0;
    .el-textarea__inner {
      .scroll-bar();
      width: 510px;
      height: 142px;
      padding: 12px;
      background: #F6F9FA;
      border: 1px solid #F1F1F1;
      &::placeholder {
        font-size: 14px;
        color: #999;
      }
    }
  }
  .el-dialog__footer {
    padding: 0;
    margin-top: 24px;
    .el-button {
      width: 200px;
      height: 44px;
      border-radius: 51px;
    }
    .el-button:last-child {
      background: #E4E3FC;
      border: none;
      color: @active-text-color;
    }
  }
}
</style>
