<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-29 15:03:26
 * @LastEditTime: 2022-11-10 17:04:55
 * @LastEditors: wangjuan
 * @FilePath: \sns_web\src\views\layout\work\okr\evaluation\components\EvaluteTop.vue
-->
<template>
    <div class="evaluation-top top-nav-cover">
      <span :class="['cursor-pointer', tabSel === '待审批' ? 'select-top' : '' ]" @click="changeTab('待审批')">待审批<Dot :count="count.audit" v-if="count.audit > 0"></Dot></span>
      <span :class="['cursor-pointer', tabSel === '待评价' ? 'select-top' : '' ]" @click="changeTab('待评价')">待评价<Dot :count="count.evaluate" v-if="count.evaluate > 0"></Dot></span>
    </div>
    <div class="cover-line"></div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  props: {
    tabSel: {
      type: String,
      default: '待审批'
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      tabSel: computed(() => props.tabSel),
      count: computed(() => store.state.okr.evaluateCount)
    })

    // console.log(state.tabSel)

    const changeTab = (value) => {
      emit('changeValue', value)
      document.scrollingElement.scrollTop = 0
    }

    return {
      ...toRefs(state),
      changeTab
    }
  }
}
</script>

<style lang="less" scoped>
.evaluation-top {
  position: relative;
  width: 955px;
  height: 46px;
  padding: 7px 15px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    top: -9px;
    left: -10px;
    display: inline-block;
    width: 110%;
    height: 8px;
    background: #f6f9fa;
    border: none;
    border: 0px;
    border-radius: 0;
  }
  & > span {
    display: inline-block;
    font-size: 13px;
    width: 67px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 44px;
    margin-right: 15px;
    border: 1px solid #F1F1F1;
    background: #F6F9FA;
    color: #999999;
    font-weight: 400;
    transition: all .3s;
    position: relative;
  }

  .select-top {
    border: 1px solid @active-text-color;
    background: #F2F1FE;
    color: @active-text-color;
    font-weight: 700;
  }
}
:deep(.dot) {
  position: absolute;
  top: -4px;
  right: -10px;
}
</style>
